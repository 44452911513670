var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('template',{slot:"body"},[_c('div',{staticClass:"d-flex"},[_c('l-map',{ref:"map",staticClass:"map",attrs:{"zoom":_vm.mapZoom,"min-zoom":2,"center":_vm.mapCenter,"max-bounds":_vm.maxBounds,"options":{ zoomControl: false }}},[_c('l-tile-layer',{attrs:{"url":'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'}}),_c('l-control-zoom',{attrs:{"position":"bottomright"}}),_vm._l((_vm.getProperties),function(property,index){return _c('l-marker',{key:("property-marker-" + index),attrs:{"lat-lng":[
            property.coordinates ? property.coordinates.latitude : 0,
            property.coordinates ? property.coordinates.longitude : 0 ]}},[_c('l-icon',{attrs:{"icon-url":require('@/assets/imgs/map_marker.png'),"icon-size":[40, 40],"icon-anchor":[20, 20]}})],1)})],2)],1),_c('FiltersSection',{on:{"input":_vm.handleInput,"reset":_vm.resetFilters,"search":_vm.fetchData}}),_c('div',{staticClass:"type-title"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('sections.properties.rent'))+" ")])]),_c('div',{staticClass:"margin-container properties-section"},_vm._l((_vm.getProperties),function(property,index){return _c('PropertyCard',{key:("property-card-" + index),attrs:{"property":property,"is-favorite":_vm.getUser ? _vm.getUser.favorites.includes(property._id) : false},on:{"click":function($event){return _vm.$router.push({
            name: 'property-detail',
            params: {
              id: property._id,
            },
          })},"favorite":function($event){return _vm.favoriteProperty(property._id)}}})}),1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }