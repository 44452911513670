<template>
  <layout>
    <template slot="body">
      <div class="d-flex">
        <l-map
          :zoom="mapZoom"
          :min-zoom="2"
          :center="mapCenter"
          :max-bounds="maxBounds"
          :options="{ zoomControl: false }"
          class="map"
          ref="map"
        >
          <l-tile-layer
            :url="'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'"
          />
          <l-control-zoom position="bottomright" />
          <l-marker
            v-for="(property, index) in getProperties"
            :key="`property-marker-${index}`"
            :lat-lng="[
              property.coordinates ? property.coordinates.latitude : 0,
              property.coordinates ? property.coordinates.longitude : 0,
            ]"
          >
            <l-icon
              :icon-url="require('@/assets/imgs/map_marker.png')"
              :icon-size="[40, 40]"
              :icon-anchor="[20, 20]"
            />
          </l-marker>
        </l-map>
      </div>

      <FiltersSection
        @input="handleInput"
        @reset="resetFilters"
        @search="fetchData"
      />

      <div class="type-title">
        <span>
          {{ $t('sections.properties.rent') }}
        </span>
      </div>

      <div class="margin-container properties-section">
        <PropertyCard
          v-for="(property, index) in getProperties"
          :key="`property-card-${index}`"
          :property="property"
          :is-favorite="
            getUser ? getUser.favorites.includes(property._id) : false
          "
          @click="
            $router.push({
              name: 'property-detail',
              params: {
                id: property._id,
              },
            })
          "
          @favorite="favoriteProperty(property._id)"
        />
      </div>
    </template>
  </layout>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  LMap, LTileLayer, LControlZoom, LMarker, LIcon,
} from 'vue2-leaflet';
import { PropertyCard } from 'ff-components-lib';
import Layout from '@/components/layout/Layout';
import FiltersSection from '@/components/sections/external/properties/FiltersSection';

export default {
  name: 'PropertiesScreen',
  components: {
    Layout,
    PropertyCard,
    LMap,
    LTileLayer,
    LControlZoom,
    LMarker,
    LIcon,
    FiltersSection,
  },
  data() {
    return {
      mapZoom: 7,
      mapCenter: [39.57012, -9.33837],
      maxBounds: [
        [-85, -10000],
        [85, 10000],
      ],
      limit: 12,
      query: {
        status: 'AVAILABLE',
        address: undefined,
        listingType: 'RENT',
        typology: undefined,
        type: undefined,
        lowerPrice: undefined,
        upperPrice: undefined,
        lowerArea: undefined,
        upperArea: undefined,
        wcs: undefined,
      },
      propertiesTotalCount: 0,
    };
  },
  computed: {
    ...mapGetters(['getBreakpoints', 'getProperties', 'getUser']),
  },
  async created() {
    this.propertiesTotalCount = await this.fetchData();
  },
  mounted() {
    const listElm = document.querySelector('.properties-section');
    // eslint-disable-next-line no-unused-vars
    listElm.addEventListener('scroll', async (_) => {
      if (
        Math.ceil(listElm.scrollTop + listElm.clientHeight)
          >= listElm.scrollHeight - 72
        && this.limit < this.propertiesTotalCount
      ) {
        this.limit += 12;
        await this.fetchData();
      }
    });
  },
  methods: {
    async fetchData() {
      this.$store.dispatch('storeLoading', true);
      const propertiesTotalCount = await this.$store.dispatch(
        'storeProperties',
        {
          query: this.query,
          limit: this.limit,
        },
      );
      this.$store.dispatch('storeLoading', false);
      return propertiesTotalCount;
    },
    handleInput({ field, value }) {
      this.query[field] = value;
      if (field === 'typology') {
        this.fetchData();
      }
    },
    resetFilters() {
      this.query = {
        status: 'AVAILABLE',
        address: undefined,
        listingType: 'RENT',
        typology: undefined,
        type: undefined,
        lowerPrice: undefined,
        upperPrice: undefined,
        lowerArea: undefined,
        upperArea: undefined,
        wcs: undefined,
      };
    },
    async favoriteProperty(propertyId) {
      if (this.getUser && this.getUser.email) {
        await this.$store.dispatch('updateUserFavorites', {
          user: this.getUser.email,
          property: propertyId,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.map {
  width: 100%;
  height: 520px;
  z-index: 1;
}

.type-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 500px;
  background: linear-gradient(
    180deg,
    rgba($primary, 0.3) 0%,
    rgba($primary, 0) 100%
  );
  margin-bottom: -288px;

  span {
    font-size: 40px;
    font-weight: 600;
    color: $primary;
    text-transform: uppercase;
    margin-top: 76px;
  }
}

.properties-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: scroll;
  max-height: 900px;

  .card {
    margin: 0 36px;
    margin-bottom: 72px;
  }
}

@media only screen and (max-width: 900px) {
  .properties-section {
    .card {
      margin: 0 26px;
      margin-bottom: 52px;
    }
  }
}

@media only screen and (max-width: 770px) {
  .type-title {
    height: 540px;
    margin-bottom: -396px;

    span {
      font-size: 32px;
      margin-top: 48px;
    }
  }
}

@media only screen and (max-width: 620px) {
  .properties-section {
    .card {
      margin: 0;
      margin-bottom: 56px;
    }
  }
}
</style>
