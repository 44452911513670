<template>
  <div class="filters-section">
    <div
      class="filters-wrapper"
      :class="open ? 'open' : ''"
      v-if="getBreakpoints.mdAndUp"
    >
      <div class="filters margin-container">
        <div
          class="filters__input"
          @keyup.enter="$emit('search')"
        >
          <input
            type="text"
            :placeholder="$t('sections.properties.filters.inputPlaceholder')"
            :value="query.address"
            :class="['filters__input-placeholder']"
            @input="(e) => handleInput('address', e.target.value)"
          >
          <span
            class="mdi mdi-magnify"
            @click="$emit('search')"
          />
        </div>
        <Dropdown
          name="listingType"
          :placeholder="$t('sections.properties.filters.listingType')"
          :value="query.listingType"
          :items="$t('sections.properties.filters.listingTypeOptions')"
          @input="(e) => handleInput('listingType', e)"
          disabled
          rounded
          v-if="!open"
        />
        <IntervalDropdown
          name="price-dropdown"
          :title="$t('sections.properties.filters.price')"
          :lower-value="query.lowerPrice"
          :upper-value="query.upperPrice"
          :lower-placeholder="
            $t('sections.properties.filters.pricePlaceholder')
          "
          :upper-placeholder="
            $t('sections.properties.filters.pricePlaceholder')
          "
          :lower-unit="$t('sections.properties.filters.priceUnit')"
          :upper-unit="$t('sections.properties.filters.priceUnit')"
          @lowerInput="(e) => handleInput('lowerPrice', e)"
          @upperInput="(e) => handleInput('upperPrice', e)"
          @search="$emit('search')"
          v-if="!open"
        />
        <Dropdown
          name="typology"
          :placeholder="$t('sections.properties.filters.typology')"
          :value="query.typology"
          :items="$t('arrays.typology')"
          @input="(e) => handleInput('typology', e)"
          v-if="!open"
        />
        <div
          class="options-button"
          @click="open = !open"
        >
          <span>{{
            open
              ? $t('sections.properties.filters.closeOptionsButton')
              : $t('sections.properties.filters.optionsButton')
          }}</span>
          <span
            class="mdi"
            :class="open ? 'mdi-unfold-less-horizontal' : 'mdi-tune'"
          />
        </div>
        <div
          class="reset-button"
          @click="resetFilters"
        >
          <span>{{ $t('sections.properties.filters.resetButton') }}</span>
          <span class="mdi mdi-restart" />
        </div>
      </div>
      <expand-transition>
        <div
          class="expanded-filters margin-container"
          v-if="open"
        >
          <div class="expanded-filters__inputs">
            <Dropdown
              name="listingType"
              :placeholder="$t('sections.properties.filters.listingType')"
              :value="query.listingType"
              :items="$t('sections.properties.filters.listingTypeOptions')"
              @input="(e) => handleInput('listingType', e)"
              disabled
            />
            <Dropdown
              name="typology"
              :placeholder="$t('sections.properties.filters.typology')"
              :value="query.typology"
              :items="$t('arrays.typology')"
              @input="(e) => handleInput('typology', e)"
            />
            <Dropdown
              name="type"
              :placeholder="$t('sections.properties.filters.type')"
              :value="query.type"
              :items="$t('sections.properties.filters.typeOptions')"
              @input="(e) => handleInput('type', e)"
            />
            <Dropdown
              name="wcs"
              :placeholder="$t('sections.properties.filters.wcs')"
              :value="query.wcs"
              :items="$t('sections.properties.filters.wcsOptions')"
              @input="(e) => handleInput('wcs', e)"
            />
            <IntervalInput
              :title="$t('sections.properties.filters.price')"
              :lower-value="query.lowerPrice"
              :upper-value="query.upperPrice"
              :lower-placeholder="
                $t('sections.properties.filters.pricePlaceholder')
              "
              :upper-placeholder="
                $t('sections.properties.filters.pricePlaceholder')
              "
              :lower-unit="$t('sections.properties.filters.priceUnit')"
              :upper-unit="$t('sections.properties.filters.priceUnit')"
              @lowerInput="(e) => handleInput('lowerPrice', e)"
              @upperInput="(e) => handleInput('upperPrice', e)"
              @search="$emit('search')"
            />
            <IntervalInput
              :title="$t('sections.properties.filters.area')"
              :lower-value="query.lowerArea"
              :upper-value="query.upperArea"
              :lower-placeholder="
                $t('sections.properties.filters.areaPlaceholder')
              "
              :upper-placeholder="
                $t('sections.properties.filters.areaPlaceholder')
              "
              :lower-unit="$t('sections.properties.filters.areaUnit')"
              :upper-unit="$t('sections.properties.filters.areaUnit')"
              @lowerInput="(e) => handleInput('lowerArea', e)"
              @upperInput="(e) => handleInput('upperArea', e)"
              @search="$emit('search')"
            />
          </div>
          <BaseButton
            class="expanded-filters__button"
            large
            :label="$t('sections.sell.featureSection.button')"
          />
        </div>
      </expand-transition>
    </div>
    <div
      class="filters-wrapper-mobile"
      v-else
    >
      <div class="filters margin-container">
        <div class="filters__input">
          <input
            type="text"
            :placeholder="$t('sections.properties.filters.inputPlaceholder')"
            :value="query.address"
            :class="['filters__input-placeholder']"
            @input="(e) => handleInput('address', e.target.value)"
          >
          <span
            class="mdi mdi-magnify"
            @click="$emit('search')"
          />
        </div>
        <div
          class="options-button"
          @click="open = !open"
        >
          <span
            class="mdi"
            :class="open ? 'mdi-unfold-less-horizontal' : 'mdi-tune'"
          />
        </div>
        <div
          class="reset-button"
          @click="resetFilters"
        >
          <span class="mdi mdi-restart" />
        </div>
      </div>
      <expand-transition>
        <div
          class="expanded-filters margin-container"
          v-if="open"
        >
          <div class="expanded-filters__inputs">
            <Dropdown
              name="listingType"
              :placeholder="$t('sections.properties.filters.listingType')"
              :value="query.listingType"
              :items="$t('sections.properties.filters.listingTypeOptions')"
              @input="(e) => handleInput('listingType', e)"
              disabled
            />
            <Dropdown
              name="typology"
              :placeholder="$t('sections.properties.filters.typology')"
              :value="query.typology"
              :items="$t('arrays.typology')"
              @input="(e) => handleInput('typology', e)"
            />
            <Dropdown
              name="type"
              :placeholder="$t('sections.properties.filters.type')"
              :value="query.type"
              :items="$t('sections.properties.filters.typeOptions')"
              @input="(e) => handleInput('type', e)"
            />
            <Dropdown
              name="wcs"
              :placeholder="$t('sections.properties.filters.wcs')"
              :value="query.wcs"
              :items="$t('sections.properties.filters.wcsOptions')"
              @input="(e) => handleInput('wcs', e)"
            />
            <IntervalInput
              :title="$t('sections.properties.filters.price')"
              :lower-value="query.lowerPrice"
              :upper-value="query.upperPrice"
              :lower-placeholder="
                $t('sections.properties.filters.pricePlaceholder')
              "
              :upper-placeholder="
                $t('sections.properties.filters.pricePlaceholder')
              "
              :lower-unit="$t('sections.properties.filters.priceUnit')"
              :upper-unit="$t('sections.properties.filters.priceUnit')"
              @lowerInput="(e) => handleInput('lowerPrice', e)"
              @upperInput="(e) => handleInput('upperPrice', e)"
              @search="$emit('search')"
            />
            <IntervalInput
              :title="$t('sections.properties.filters.area')"
              :lower-value="query.lowerArea"
              :upper-value="query.upperArea"
              :lower-placeholder="
                $t('sections.properties.filters.areaPlaceholder')
              "
              :upper-placeholder="
                $t('sections.properties.filters.areaPlaceholder')
              "
              :lower-unit="$t('sections.properties.filters.areaUnit')"
              :upper-unit="$t('sections.properties.filters.areaUnit')"
              @lowerInput="(e) => handleInput('lowerArea', e)"
              @upperInput="(e) => handleInput('upperArea', e)"
              @search="$emit('search')"
            />
          </div>
          <BaseButton
            class="expanded-filters__button"
            large
            :label="$t('sections.sell.featureSection.button')"
          />
        </div>
      </expand-transition>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  Dropdown, BaseButton, IntervalInput, IntervalDropdown,
} from 'ff-components-lib';

export default {
  name: 'FiltersSection',
  components: {
    Dropdown,
    IntervalDropdown,
    IntervalInput,
    BaseButton,
  },
  data() {
    return {
      open: false,
      query: {
        status: 'AVAILABLE',
        address: undefined,
        listingType: 'RENT',
        typology: undefined,
        type: undefined,
        lowerPrice: undefined,
        upperPrice: undefined,
        lowerArea: undefined,
        upperArea: undefined,
        wcs: undefined,
      },
    };
  },
  computed: {
    ...mapGetters(['getBreakpoints']),
  },
  methods: {
    handleInput(field, value) {
      this.query[field] = value;
      this.$emit('input', { field, value });
    },
    resetFilters() {
      this.query = {
        status: 'AVAILABLE',
        address: undefined,
        listingType: 'RENT',
        typology: undefined,
        type: undefined,
        lowerPrice: undefined,
        upperPrice: undefined,
        lowerArea: undefined,
        upperArea: undefined,
        wcs: undefined,
      };
      this.$emit('reset');
    },
  },
};
</script>
<style lang="scss" scoped>
.filters-wrapper {
  width: 100%;
  background: white;
  box-shadow: 0px 2px 4px 0px rgba($primary, 0.1);
  padding: 16px 0;
  transition: all 0.3s ease;

  &.open {
    padding: 32px 0;
  }

  .filters {
    display: flex;
    align-items: center;
    gap: 48px;

    &__input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 60px;
      padding: 12px 24px;
      flex: 1;
      border-radius: 50px;
      border: 1px solid rgba($grey, 0.4);

      &-placeholder {
        font-size: 14px;
        font-weight: 500;
        color: rgba($grey, 0.6);
        outline: none;
        width: 100%;
      }

      .mdi {
        font-size: 24px;
        line-height: 1;
        color: $primary;
        cursor: pointer;
      }
    }

    .reset-button,
    .options-button {
      display: flex;
      gap: 8px;

      color: $grey;
      font-weight: 500;
      cursor: pointer;

      .mdi {
        font-size: 24px;
        line-height: 1;
      }
    }

    ::v-deep .dropdown--wrapper:not(.disabled) {
      padding: 12px 0;
    }
  }

  .expanded-filters {
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding-top: 24px;

    &__inputs {
      /* display: grid;
      gap: 24px;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));*/
      display: flex;
      gap: 48px;
      flex-wrap: wrap;
      align-items: center;

      ::v-deep .dropdown--wrapper:not(.disabled) {
        padding: 12px 0;
      }

      ::v-deep .interval-input {
        .base-input {
          max-width: 150px;
        }
      }
    }

    &__button {
      align-self: center;
    }
  }
}

.filters-wrapper-mobile {
  width: 100%;
  background: white;
  box-shadow: 0px 2px 4px 0px rgba($primary, 0.1);
  padding: 16px 0;
  transition: all 0.3s ease;

  .filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    &__input {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 10px 24px;
      flex: 1;
      border-radius: 50px;
      border: 1px solid rgba($grey, 0.4);

      &-placeholder {
        font-size: 12px;
        font-weight: 500;
        color: rgba($grey, 0.6);
        outline: none;
        width: 100%;
      }

      .mdi {
        font-size: 20px;
        line-height: 1;
        color: $primary;
      }
    }

    .options-button,
    .reset-button {
      color: $grey;
      cursor: pointer;

      .mdi {
        font-size: 24px;
        line-height: 1;
      }
    }
  }

  .expanded-filters {
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding-top: 24px;

    &__inputs {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
      align-items: center;

      ::v-deep .dropdown--wrapper:not(.disabled) {
        padding: 12px 0;
      }

      ::v-deep .interval-input {
        .base-input {
          max-width: 150px;
        }
      }
    }

    &__button {
      align-self: center;
    }
  }
}
</style>
